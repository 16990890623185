import React from "react";
import styled from "styled-components";
import { useLocation } from "@reach/router";

import { useFooterQuery } from "@api";
import { colors, TABLET_BREAKPOINT } from "@util/constants";
import { Container, P, A, GiveMeSomeSpace } from "@util/standard";
import { BlocksContent, Cta, Image } from "@global";
import { MobileContainer, DesktopContainer } from "./shared.styles";

const FooterWrapper = styled.div`
  width: 100%;
  background-color: ${colors.cascade};
  p,
  h3,
  a {
    color: ${colors.white};

    &:first-child {
      margin-top: 0px;
    }
  }
  .links-wrapper {
    a {
      margin: 10px 0;
    }
  }
`;

const MobileImageWrapper = styled(MobileContainer)`
  .gatsby-image-wrapper {
    width: 250px;
  }
`;

const FooterContainer = styled(Container)`
  justify-content: space-between;

  width: 80%;
  margin: auto;
  padding: 80px 0;

  @media only screen and (max-width: ${TABLET_BREAKPOINT}px) {
    padding: 50px 0 60px 0;
    flex-direction: column;
    align-items: flex-start;
  }
`;

const Footer = () => {
  const { sanityFooter } = useFooterQuery();

  if (sanityFooter == null) {
    return null;
  }

  const { copyright, logo, title, phone, email, image, links } = sanityFooter;
  const { pathname } = useLocation();

  return (
    <FooterWrapper>
      <FooterContainer>
        <Container flexDirection="column">
          <MobileImageWrapper>
            <Image
              imageData={logo?.asset?.gatsbyImageData}
              altText={logo?.altText ?? "molloy-logo"}
              margin="0 0 20px 0"
            />
          </MobileImageWrapper>

          {pathname != "/contact" && (
            <div>
              <h3>{title}</h3>
              <GiveMeSomeSpace />
              <P noMargin fontWeight="bold">
                Phone
              </P>
              <A href={`tel:${phone}`}>{phone}</A>
              <P margin="20px 0 0 0" fontWeight="bold">
                Email
              </P>
              <A margin="0 0 50px 0" href={`mailto:${email}`}>
                {email}
              </A>
            </div>
          )}

          <MobileImageWrapper margin="50px 0 25px 0">
            <Image
              imageData={image?.asset?.gatsbyImageData}
              altText={image?.altText ?? "molloy-image"}
            />
          </MobileImageWrapper>
          <DesktopContainer margin="30px" />
          {links && (
            <Container flexDirection="column" className="links-wrapper">
              {links.map(link => {
                if (link == null) return null;

                return <Cta isLinkText key={link._key} data={link} />;
              })}
            </Container>
          )}
          <BlocksContent data={copyright} />
        </Container>
        <DesktopContainer
          flexDirection="column"
          justifyContent="space-between"
          alignItems="flex-end"
        >
          <Image
            imageData={logo?.asset?.gatsbyImageData}
            altText={logo?.altText ?? "molloy-logo"}
            margin="0 0 50px 0"
          />

          <Image
            imageData={image?.asset?.gatsbyImageData}
            altText={image?.altText ?? "molloy-image"}
          />
        </DesktopContainer>
      </FooterContainer>
    </FooterWrapper>
  );
};

export default Footer;
