import { useStaticQuery, graphql } from "gatsby";

import { Query } from "@graphql-types";

const FooterQuery = () => {
  const footerData: Query = useStaticQuery(graphql`
    {
      sanityFooter {
        title
        phone
        email
        copyright {
          _rawBlockContent
        }
        logo {
          asset {
            _id
            gatsbyImageData(layout: FIXED, height: 120, placeholder: BLURRED, formats: [AUTO, WEBP])
          }
        }
        image {
          asset {
            _id
            gatsbyImageData(layout: FIXED, height: 60, placeholder: BLURRED, formats: [AUTO, WEBP])
          }
        }
        links {
          ... on SanityLink {
            ...sanityLink
          }
          ... on SanityPdfFile {
            ...sanityPdfFile
          }
        }
      }
    }
  `);

  return footerData;
};

export default FooterQuery;
