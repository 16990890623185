import React, { ReactNode } from "react";
import { Helmet } from "react-helmet";

import Footer from "./footer";
import { GlobalStyle, Main } from "@styles/globalStyles";
interface Props {
  children: ReactNode;
}

function Layout(props: Props) {
  const { children } = props;

  return (
    <div>
      <Helmet>
        <meta
          name="google-site-verification"
          content="lo_o3qpAwVdnJjBrz0vGm2rfHsdUun6f7qSWu1FY9Io"
        />
        <link rel="preconnect" href="https://fonts.googleapis.com" />
        {/* @ts-ignore */}
        <link rel="preconnect" href="https://fonts.gstatic.com" crossOrigin />
        <link
          href="https://fonts.googleapis.com/css2?family=Nunito+Sans:wght@300;400;600&display=swap"
          rel="stylesheet"
        />
        <link rel="stylesheet" href="https://use.typekit.net/mlc0qfn.css" />
      </Helmet>
      <GlobalStyle />
      <Main>{children}</Main>
      <Footer />
    </div>
  );
}

export default Layout;
