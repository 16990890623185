import React from "react";
import styled from "styled-components";
import { Link as GatsbyLink } from "gatsby";

import { ButtonTheme, Dimensions } from "@util/types";
import { buttonStyle } from "@util/constants";
import Loading from "./loading";
import { Container, GiveMeSomeSpace, P, A } from "@util/standard";

const StyledButton = styled.button<{
  theme: ButtonTheme;
  dimensions?: Dimensions;
  mobileDimensions?: Dimensions;
  disableHover?: boolean;
  mobileMargin?: string;
  margin?: string;
  minHeight?: number;
  width?: string;
  padding?: string;
  fontSize?: number;
}>`
  ${({ fontSize }) => fontSize && `font-size: ${fontSize}px;`}
  text-align: center;
  cursor: pointer;
  user-select: none;
  ${({ margin }) => margin && `margin: ${margin};`}
  padding: ${props => (props.padding ? props.padding : `15px 35px`)};
  ${({ width }) => width && `width: ${width};`}
  height: ${props => props.dimensions?.height ?? `auto`};
  color: ${props => props.theme.text};
  border: 2px solid ${props => props.theme.border};
  background-color: ${props => props.theme.bg};
  transition: background-color 0.1s linear;

  &:focus {
    outline: 0;
  }
  ${({ disableHover, theme }) =>
    !disableHover &&
    `&:hover {
      outline: 0;
      border-color: ${theme.hoverBorder};
      color: ${theme.hoverText};
      background-color: ${theme.hoverBg};
  }`}

  ${({ minHeight }) => minHeight && `min-height: ${minHeight}px;`}
`;

const LoadingContainer = styled(Container)`
  flex: 1;
  justify-content: center;
`;

interface Props {
  theme?: ButtonTheme;
  disabled?: boolean;
  className?: string;
  dimensions?: Dimensions;
  mobileDimensions?: Dimensions;
  minHeight?: number;
  text: string;
  margin?: string;
  padding?: string;
  disableHoverEffect?: boolean;
  mobileMargin?: string;
  onClick?: (args?: any) => void;
  loading?: boolean;
  type?: "button" | "submit" | "reset";
  linkTo?: string;
  linkToNewWindow?: string;
  width?: string;
}

const Button = ({
  theme = "base",
  disabled,
  dimensions,
  width,
  mobileDimensions,
  text,
  margin,
  disableHoverEffect,
  onClick,
  mobileMargin,
  loading,
  minHeight,
  padding,
  type,
  linkTo,
  linkToNewWindow,
}: Props) => {
  const Loader = () => (
    <LoadingContainer>
      <>
        <Loading />
        <GiveMeSomeSpace space={3} />
        <P noMargin color="white">
          Loading..
        </P>
      </>
    </LoadingContainer>
  );

  const handleOnClick = () => {
    if (loading) {
      return;
    }
    if (onClick) {
      onClick();
    }
  };

  const RenderedButton = ({ externalLink }: { externalLink?: string | undefined }) => (
    <StyledButton
      theme={buttonStyle[theme]}
      dimensions={dimensions}
      width={width}
      disabled={disabled}
      onClick={handleOnClick}
      margin={margin}
      padding={padding}
      mobileDimensions={mobileDimensions}
      disableHover={loading || disableHoverEffect}
      mobileMargin={mobileMargin}
      minHeight={minHeight}
      type={type}
    >
      {loading ? (
        <Loader />
      ) : externalLink ? (
        <A href={externalLink} hoverColor="white" target="_blank" rel="noreferrer">
          {text}
        </A>
      ) : (
        text
      )}
    </StyledButton>
  );

  if (linkTo) {
    return (
      <GatsbyLink to={linkTo}>
        <RenderedButton />
      </GatsbyLink>
    );
  }

  return <RenderedButton externalLink={linkToNewWindow} />;
};

export default Button;
