import React from "react";

import { SanityLinkOrPdfFile } from "@graphql-types";
import { Button, Link } from "@global";
import { P, A } from "@util/standard";
import { ButtonTheme, isSanityLink, isSanityPdfFile } from "@util/types";
import { isBrowser } from "@util/helper";

interface Props {
  data: SanityLinkOrPdfFile;
  height?: string | undefined;
  theme?: ButtonTheme;
  isLinkText?: boolean;
}

const Cta = ({ data, height, theme, isLinkText }: Props) => {
  if (isSanityPdfFile(data)) {
    if (!isBrowser()) {
      return null;
    }

    const { buttonText, guide } = data;
    const fileUrl = guide?.asset?.url;

    if (fileUrl == null) {
      return null;
    }

    if (isLinkText) {
      return (
        <A color="white" target="_blank" href={`${fileUrl}?dl=${guide?.asset?.originalFilename}`}>
          {buttonText}
        </A>
      );
    }

    const handleClick = () => {
      //@ts-ignore
      window.open(fileUrl, "_blank").focus();
    };

    return (
      <Button
        theme={theme ? theme : height ? "white" : "base"}
        text={buttonText as string}
        onClick={handleClick}
        margin="0 15px 0 0"
      />
    );
  }

  if (isSanityLink(data)) {
    const { url, linktext } = data;
    if (linktext == null) {
      return null;
    }

    if (isLinkText) {
      return <Link {...data} />;
    }

    return (
      <Button
        key={data._key}
        theme={theme ? theme : height ? "white" : "base"}
        text={linktext as string}
        linkTo={url as string}
        margin="0 15px 0 0"
      />
    );
  }

  return <P>Error loading cta...</P>;
};

export default Cta;
