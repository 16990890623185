import React, { CSSProperties, ReactNode } from "react";
import { GatsbyImage, IGatsbyImageData } from "gatsby-plugin-image";
import { convertToBgImage, BgImage } from "gbimage-bridge";
import styled from "styled-components";

import { Container } from "@util/standard";
import { Maybe } from "@graphql-types";
import { colors } from "@util/constants";

const StyledImage = styled(GatsbyImage)<{
  width?: string;
}>`
  width: ${props => props.width ?? "100%"};
`;

const BackgroundGatsbyImage = styled(GatsbyImage)<{}>`
  position: absolute !important;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`;

const BackgroundImageWrapper = styled(Container)<{ minHeight?: string }>`
  width: 100%;
  height: 100vh;
  flex-direction: column;
  margin: 0;
  position: relative;
  overflow: hidden;
  ${({ minHeight }) => minHeight && `min-height: ${minHeight};`}
`;

interface Props {
  imageData?: IGatsbyImageData;
  staticImage?: string;
  minHeight?: string;
  isBackground?: boolean;
  width?: string;
  height?: string;
  altText?: Maybe<string> | undefined;
  children?: ReactNode;
  onClick?: () => void;
  objectFit?: CSSProperties["objectFit"];
  margin?: string;
}

const Image = (props: Props) => {
  const { minHeight, objectFit } = props;

  if (props.isBackground && props.imageData) {
    const bgImage = convertToBgImage(props.imageData);
    return (
      <BgImage preserveStackingContext {...bgImage}>
        <BackgroundImageWrapper minHeight={props.minHeight}>
          <BackgroundGatsbyImage
            image={props.imageData as IGatsbyImageData}
            alt="bg-image"
            objectFit="cover"
          />
          <BackgroundImageWrapper>{props.children}</BackgroundImageWrapper>
        </BackgroundImageWrapper>
      </BgImage>
    );
  }

  if (props.staticImage) {
    return (
      <img
        src={props.staticImage}
        width={props.width}
        height={props.height ?? "100%"}
        style={{ minHeight, objectFit }}
        itemProp="image"
        alt={props.altText ?? "image"}
      />
    );
  }

  if (props.imageData) {
    return (
      <StyledImage
        image={props.imageData}
        style={{ minHeight, margin: props.margin }}
        width={props.width}
        itemProp="image"
        alt={props.altText ?? "gatsby-image"}
        onClick={props.onClick}
        objectFit={objectFit ?? "contain"}
      />
    );
  }

  return null;
};

export default Image;
