import React, { useState } from "react";
import styled from "styled-components";

import { TABLET_BREAKPOINT } from "@util/constants";
import { graphql, useStaticQuery } from "gatsby";
import { A, Container, GiveMeSomeSpace } from "@util/standard";
import BlocksContent from "./blocksContent";
import Button from "./button";
import { Query } from "@graphql-types";

interface Props {
  hideContactInfo?: boolean;
}

export default function ContactForm({ hideContactInfo }: Props) {
  const data = useContactPage();
  const [submitted, setSubmitted] = useState(false);

  if (!data) return null;
  const { blockContent, phone, email, address, postalAddress } = data;

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    const form = event.target as HTMLFormElement;

    const formDataFormatted = new FormData(form);

    console.log(new URLSearchParams(formDataFormatted).toString());

    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: new URLSearchParams(formDataFormatted).toString(),
    })
      .then(() => {
        setSubmitted(true);
      })
      .catch(error => {
        console.error(error);
      });
  };

  const ContactInfo = () => (
    <>
      <BlocksContent data={blockContent} />
      <span />
      <Container
        width="100%"
        margin="auto"
        flexDirection="column"
        height="100%"
        justifyContent="flex-start"
      >
        <PhoneEmailContainer>
          <div>
            <Label>Phone</Label>
            <A href={`tel:${phone}`}>{phone}</A>
          </div>
          <GiveMeSomeSpace space={50} tabletSpace={25} />
          <div>
            <Label>Email</Label>
            <A href={`mailto:${email}`}>{email}</A>
          </div>
        </PhoneEmailContainer>
        <div>
          <Label>Address</Label>
          <BlocksContent data={address} />
        </div>
        <Container margin="15px 0 0 0" flexDirection="column">
          <Label>Postal Address</Label>
          <BlocksContent data={postalAddress} />
        </Container>
      </Container>
    </>
  );

  return (
    <Wrapper className="content-wrapper" hideContactInfo={hideContactInfo} id="contact-form">
      {!hideContactInfo && <ContactInfo />}
      <Container
        width="100%"
        margin="50px auto"
        flexDirection="column"
        height="100%"
        justifyContent="flex-start"
      >
        <Label>Enquiries</Label>
        {submitted ? (
          <p>Your form submission has been received. We will be in touch shortly.</p>
        ) : (
          <StyledForm
            data-netlify="true"
            netlify-honeypot="spam-trap"
            name="contact-form"
            onSubmit={handleSubmit}
          >
            <input type="hidden" name="form-name" value="contact-form" />

            <input type="text" name="name" placeholder="Name" required />
            <input type="email" name="email" placeholder="Email" required />
            <textarea name="message" placeholder="Message" required />
            <Button theme="blue" text="Submit" width="fit-content" />
          </StyledForm>
        )}
      </Container>
    </Wrapper>
  );
}

const useContactPage = () => {
  const { sanityContact }: Query = useStaticQuery(graphql`
    {
      sanityContact {
        ...sanityContact
      }
    }
  `);

  return sanityContact;
};

const Wrapper = styled.div<{ hideContactInfo?: boolean }>`
  margin: auto;
  display: grid;
  grid-template-columns: ${props => (props.hideContactInfo ? "1fr" : "1fr 1fr")};
  width: 80%;
  padding-top: ${props => (props.hideContactInfo ? "0" : "150px")};

  @media only screen and (max-width: ${TABLET_BREAKPOINT}px) {
    grid-template-columns: 1fr;
    padding-top: 100px;
    width: 90%;
  }
`;

const Label = styled.span`
  display: block;
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 15px;
`;

const PhoneEmailContainer = styled(Container)`
  margin: 50px 0 0 0;
  @media only screen and (max-width: ${TABLET_BREAKPOINT}px) {
    flex-direction: column;
    margin: 50px 0 50px 0;
  }
`;

const StyledForm = styled.form`
  display: flex;
  flex-direction: column;

  input {
    margin-bottom: 20px;
    height: 30px;
    padding: 10px;
    font-family: "Nunito Sans";
  }

  textarea {
    margin-bottom: 20px;
    height: 100px;
    padding: 10px;
    font-family: "Nunito Sans";
  }
`;
