import styled from "styled-components";

import { colors, TABLET_BREAKPOINT } from "@util/constants";
import { Container } from "@util/standard";

export const FullHeightContainer = styled(Container)<{
  height?: string | undefined;
  width?: string | undefined;
}>`
  flex-direction: column;
  position: relative;
  width: ${props => props.width ?? "100%"};
  height: ${props => props.height ?? "100vh"};
  overflow: hidden;

  h1,
  h2,
  h3,
  h4 {
    color: ${colors.white};
  }
`;

export const MobileContainer = styled(Container)`
  display: none;
  @media only screen and (max-width: ${TABLET_BREAKPOINT}px) {
    display: flex;
  }
`;

export const DesktopContainer = styled(Container)`
  @media only screen and (max-width: ${TABLET_BREAKPOINT}px) {
    display: none;
  }
`;
