import { Dispatch, SetStateAction } from "react";
import { assets, buttonStyle, colors, colorsRGB, fontWeights } from "./constants";

import {
  SanityColumnContent,
  SanityHero,
  SanityImage,
  SanityLink,
  SanityPdfFile,
  SanityServices,
  SanityTestimonials,
} from "@graphql-types";

export type Dimensions = {
  width?: string;
  height?: string;
};

export type Asset = keyof typeof assets;
export type Color = keyof typeof colors;
export type ColorRGB = keyof typeof colorsRGB;
export type ButtonTheme = keyof typeof buttonStyle;
export type ButtonType = typeof buttonStyle;
export type FontWeights = keyof typeof fontWeights;
export type AssetType = typeof assets;

export type SetRegion = Dispatch<
  SetStateAction<{
    iban: string;
    langCode: string;
  }>
>;

export type AnimationDirection = {
  to: {
    transform?: string;
    opacity?: number;
  };
  from: {
    transform?: string;
    opacity?: number;
  };
};
export interface ScreenWidth {
  isTabletWidth: boolean;
  isMobileWidth: boolean;
  isLaptopWidth: boolean;
}

export function isSanityImage(data: any): data is SanityImage {
  return data._type === "image";
}

export function isSanityHero(data: any): data is SanityHero {
  return data._type === "hero";
}

export function isSanityColumnContent(data: any): data is SanityColumnContent {
  return data._type === "columnContent";
}

export function isSanityServices(data: any): data is SanityServices {
  return data._type === "services";
}

export function isSanityTestimonials(data: any): data is SanityTestimonials {
  return data._type === "testimonials";
}

export function isSanityPdfFile(data: any): data is SanityPdfFile {
  return data._type === "pdfFile";
}

export function isSanityLink(data: any): data is SanityLink {
  return data._type === "link";
}
