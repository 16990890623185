import { useStaticQuery, graphql } from "gatsby";

import { Query } from "@graphql-types";

const HeaderQuery = () => {
  const headerData: Query = useStaticQuery(graphql`
    {
      sanityNavigation {
        _id
        links {
          ...sanityLink
        }
        logoScrolled {
          ...sanityImageFullWidth
        }
        logo {
          ...sanityImageFullWidth
        }
      }
    }
  `);

  return headerData;
};

export default HeaderQuery;
