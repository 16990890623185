export const BUTTON_HEIGHT = 46;
export const HEADER_HEIGHT = 84;

// screen sizes
export const SMALLER_MOBILE = 400;
export const MOBILE_BREAKPOINT = 600;
export const IPAD_BREAKPOINT = 768;
export const TABLET_BREAKPOINT = 1000;
export const IPAD_PRO_BREAKPOINT = 1025;
export const SMALL_LAPTOP_BREAKPOINT = 1280;
export const LAPTOP_BREAKPOINT = 1441;
export const TWENTY_INCH_SCREEN = 1600;
export const IMAC_BREAKPOINT = 1921;
export const DEFAULT_OFFSET = 120;
export const MOBILE_OFFSET = 60;

export const colors = {
  transparent: "transparent",
  white: "#fff",
  black: "#000",
  flightBlue: "#001CA8",
  base: "#4C4C4C",
  blue: "#0056A1",
  cascade: "#97AAAA",
  marigold: "#C3932D",
  lightCascade: "#F0F2F2",
};

export const fontWeights = {
  normal: 400,
  medium: 500,
  bold: 600,
  light: 300,
};

export const PRIMARY_COLOR = colors.base;
export const SECONDARY_COLOR = colors.white;

export const fontSizes = {
  p: { default: 14, mobile: 14 },
  h1: { default: 55, mobile: 30 },
  h2: { default: 45, mobile: 35 },
  h3: { default: 35, mobile: 25 },
  h4: { default: 20, mobile: 18 },
  buttom: { default: 14, mobile: 14 },
};

export const colorsRGB = {
  flightBlue: (opacity?: number) => `rgba(0, 28, 168, ${opacity ?? "0.4"})`,
  black: (opacity?: number) => `rgba(0, 0, 0, ${opacity ?? "0.2"})`,
  white: (opacity?: number) => `rgba(255, 255, 255, ${opacity ?? "0.3"})`,
  cascade: (opacity?: number) => `rgba(151, 170, 170, ${opacity ?? "0.15"})`,
};

export const pages = {
  home: "/",
  aboutUs: "/about-us",
  contactUs: "/contact-us",
  search: "/search",
  notFound: "/404",
};

// define paths to asset
export const assets = {
  logo: require("@static/assets/logo.png").default,
  molloy: require("@static/assets/molloy.svg").default,
  molloyBlue: require("@static/assets/molloy-blue.svg").default,
  placeholder: require("@static/assets/placeholder.svg").default,
  left: require("@static/assets/left.svg").default,
  right: require("@static/assets/right.svg").default,
  background: require("@static/assets/background.svg").default,
};

export const buttonStyle = {
  base: {
    bg: `${colors.cascade}`,
    text: `${colors.white}`,
    border: `${colors.transparent}`,
    hoverBg: `${colors.transparent}`,
    hoverBorder: `${colors.white}`,
    hoverText: `${colors.white}`,
  },
  white: {
    bg: `${colors.transparent}`,
    text: `${colors.white}`,
    border: `${colors.white}`,
    hoverBg: `${colors.marigold}`,
    hoverBorder: `${colors.marigold}`,
    hoverText: `${colors.white}`,
  },
  blue: {
    bg: `${colors.transparent}`,
    text: `${colors.blue}`,
    border: `${colors.blue}`,
    hoverBg: `${colors.transparent}`,
    hoverBorder: `${colors.cascade}`,
    hoverText: `${colors.cascade}`,
  },
};
