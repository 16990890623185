import styled, { createGlobalStyle } from "styled-components";

import { colors, MOBILE_BREAKPOINT } from "@util/constants";
import { fontSizes, fontWeights, PRIMARY_COLOR } from "@util/constants";

export const GlobalStyle = createGlobalStyle`
  
  body {
    margin: 0px;
    font-family: "Nunito Sans";
    font-size: ${fontSizes.p.default}px;
    font-weight: ${fontWeights.normal};
    color: ${PRIMARY_COLOR};
  }
  p{
    margin: 0.5em 0;
  }
  h1, h2, h3{
    line-height: 1.08;
    span{
      padding: 0.2em 0;
    }
  }
  h1 {
    font-size: ${fontSizes.h1.default}px;
    font-weight: ${fontWeights.normal};
  }
  h2 {
    font-size: ${fontSizes.h2.default}px;
    font-weight: ${fontWeights.bold};
    margin: 10px 0;
  }
  h3 {
    font-size: ${fontSizes.h3.default}px;
    font-weight: ${fontWeights.bold};
    margin: 10px 0;
  }
  h4 {
    font-size: ${fontSizes.h4.default}px;
    font-weight: ${fontWeights.bold};
    margin: 0;
  }

  a {
    user-select: none;
    text-decoration: none;
    white-space: nowrap;
    text-overflow: ellipsis;
    color: ${colors.blue};

    &:hover {
      color: ${colors.blue};
    }
  }

  @media only screen and (max-width: ${MOBILE_BREAKPOINT}px) {
    body {
    font-size: ${fontSizes.p.mobile}px;
    }
    h1 {
      font-size: ${fontSizes.h1.mobile}px;
    }
    h2 {
      font-size: ${fontSizes.h2.mobile}px;
    }
    h3 {
      font-size: ${fontSizes.h3.mobile}px;
    }
    h4 {
      font-size: ${fontSizes.h4.mobile}px;
    }
  }

`;

export const Main = styled.main<{}>``;
