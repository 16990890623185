import React from "react";
import styled from "styled-components";

import { Maybe, SanityColumnContent } from "@graphql-types";
import { Container } from "@util/standard";
import { BlocksContent, Cta, Image } from "@global";
import { SMALL_LAPTOP_BREAKPOINT } from "@util/constants";

const MainContainer = styled(Container)<{ reverse: Maybe<boolean> | undefined }>`
  flex-direction: ${props => (props.reverse ? "row-reverse" : "row")};
  overflow: hidden;

  @media only screen and (max-width: ${SMALL_LAPTOP_BREAKPOINT}px) {
    flex-direction: column;
    width: 100%;
  }
`;

const ContentContainer = styled(Container)`
  position: relative;
  flex-direction: column;
  width: 100%;
`;

const BlockContainer = styled(Container)`
  width: 80%;
  margin: 80px auto;
  position: relative;
  flex-direction: column;
  .portable-text {
    h3 {
      margin: 25px 0;
    }
    h4 {
      margin: 15px 0;
    }
  }
  @media only screen and (max-width: ${SMALL_LAPTOP_BREAKPOINT}px) {
    width: 90%;
    margin: 40px auto;
  }
`;

const MediaContainer = styled(Container)`
  margin: 15px;
  width: 100%;

  @media only screen and (max-width: ${SMALL_LAPTOP_BREAKPOINT}px) {
    width: initial;
  }
`;

interface Props {
  data: Maybe<SanityColumnContent> | undefined;
}

const ColumnContent = ({ data }: Props) => {
  if (data == null) {
    return null;
  }

  const { firstColumn, secondColumn, reverse, links } = data;

  return (
    <MainContainer reverse={reverse}>
      {firstColumn && (
        <MediaContainer>
          <Image altText={firstColumn.altText} imageData={firstColumn.asset?.gatsbyImageData} />
        </MediaContainer>
      )}

      <ContentContainer>
        <BlockContainer>
          <BlocksContent data={secondColumn} />
          {links && (
            <Container margin="30px 0 0 0">
              {links.map(link => {
                if (link == null) {
                  return link;
                }

                return <Cta key={link._key} data={link} theme="blue" />;
              })}
            </Container>
          )}
        </BlockContainer>
      </ContentContainer>
    </MainContainer>
  );
};

export default ColumnContent;
