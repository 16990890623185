import React, { ReactNode, useState, RefObject } from "react";
import ReactSlick, { Settings } from "react-slick";
import styled from "styled-components";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { assets } from "@util/constants";
interface Props {
  children: ReactNode;
  hideArrow?: boolean;
  moreSettings?: any;
  sliderRef?: RefObject<ReactSlick>;
  variableWidth?: boolean;
  adaptiveHeight?: boolean;
  infinite?: boolean;
  slidesToShow?: number;
}

const Arrow = styled.img`
  object-fit: contain;
`;

const CarouselWrapper = styled.div`
  li {
    margin: 0;
  }
  .slick-dots {
    text-align: left;
    bottom: -55px;
    left: -5px;
  }
  .slick-dots li button:before {
    font-size: 12px;
  }
`;

const Carousel = ({
  children,
  hideArrow,
  sliderRef,
  variableWidth,
  adaptiveHeight,
  infinite,
  slidesToShow,
}: Props) => {
  const [slideIndex, setSlideIndex] = useState(0);

  const settings: Settings = {
    dots: true,
    infinite: infinite ?? false,
    speed: 500,
    autoplay: false,
    autoplaySpeed: 10000,
    slidesToShow: slidesToShow ?? 1,
    slidesToScroll: 1,
    arrows: hideArrow ? false : true,
    pauseOnHover: true,
    nextArrow: hideArrow ? undefined : (
      <Arrow alt={`next-index-${slideIndex + 1}`} src={assets.right} width="20px" height="20px" />
    ),
    prevArrow: hideArrow ? undefined : (
      <Arrow alt={`next-index-${slideIndex - 1}`} src={assets.left} width="20px" height="20px" />
    ),
    beforeChange: (_current, next) => setSlideIndex(next),
    variableWidth: variableWidth ? true : false,
    adaptiveHeight: adaptiveHeight ? true : false,
  };

  return (
    <CarouselWrapper>
      <ReactSlick ref={sliderRef} {...settings}>
        {children}
      </ReactSlick>
    </CarouselWrapper>
  );
};

export default Carousel;
