import React, { createRef, useEffect, useState } from "react";
import styled from "styled-components";
import ReactSlick from "react-slick";

import { SanityServices } from "@graphql-types";
import { Container, H2 } from "@util/standard";
import { BlocksContent, Carousel, Cta, Hero, Link } from "@global";
import { assets, TABLET_BREAKPOINT } from "@util/constants";
import { useCheckScreenWidth } from "@util/hooks";

interface Props {
  data: SanityServices;
}

const ServicesBlock = ({ data }: Props) => {
  const { title, description, blocks, extraText, extraCta } = data;
  const sliderRef = createRef<ReactSlick>();
  const [slideIndex, setSlideIndex] = useState(0);
  const { isTabletWidth, isMobileWidth } = useCheckScreenWidth();

  const handleArrowClick = (num: number) => {
    if (blocks == null) {
      return;
    }

    if (num == -1 && slideIndex == 0) {
      return;
    }

    if (num == 1 && slideIndex == blocks?.length - 1) {
      setSlideIndex(0);
      return;
    }

    setSlideIndex(slideIndex + num);
  };

  useEffect(() => {
    if (sliderRef.current) {
      sliderRef.current.slickGoTo(slideIndex);
    }
  }, [slideIndex]);

  const setSlidesToShow = () => {
    if (blocks) {
      if (isMobileWidth || isTabletWidth) {
        return 1.05;
      }

      if (blocks.length < 4) {
        return blocks?.length;
      }
      return 3.05;
    }
    return 0;
  };

  const showArrows = () => {
    if (blocks) {
      if (isMobileWidth || isTabletWidth) return blocks.length > 1;
      return blocks.length > 3;
    }
    return true;
  };

  return (
    <Wrapper>
      <H2>{title}</H2>
      {description && (
        <Container margin="0 0 35px 0" maxWidth="650px">
          <BlocksContent data={description} />
        </Container>
      )}
      {blocks && (
        <Carousel hideArrow infinite sliderRef={sliderRef} slidesToShow={setSlidesToShow()}>
          {blocks.map(block => {
            if (block == null) {
              return null;
            }

            return <Hero key={block._key} heroData={block} height="520px" width="95%" />;
          })}
        </Carousel>
      )}

      {showArrows() && (
        <ArrowsContainer>
          <Arrow
            alt={`prev-index`}
            src={assets.left}
            width="20px"
            height="20px"
            onClick={() => handleArrowClick(-1)}
          />
          <Arrow
            alt={`next-index`}
            src={assets.right}
            width="20px"
            height="20px"
            onClick={() => handleArrowClick(1)}
          />
        </ArrowsContainer>
      )}
      {extraText && (
        <Container margin="35px 0 0 0" maxWidth="650px">
          <BlocksContent data={extraText} />
        </Container>
      )}
      {extraCta && (
        <Container margin="35px 0 0 0" maxWidth="650px">
          <Cta data={extraCta} theme="blue" />
        </Container>
      )}
    </Wrapper>
  );
};

export default ServicesBlock;

const Wrapper = styled(Container)`
  flex-direction: column;
  width: 90%;
  margin: 80px 0 80px auto;
  h2 {
    margin: 0 0 35px 0;
  }
  @media only screen and (max-width: ${TABLET_BREAKPOINT}px) {
    margin: 50px 0 50px auto;
  }
`;

const Arrow = styled.img`
  object-fit: contain;
  margin-left: 55px;
  cursor: pointer;
  user-select: none;
`;

const ArrowsContainer = styled(Container)`
  align-self: flex-end;
  margin: 35px 55px 0 auto;
  z-index: 1;

  @media only screen and (max-width: ${TABLET_BREAKPOINT}px) {
    margin: 35px 20px 0 auto;
  }
`;
