import React from "react";
import styled from "styled-components";

interface Props {
  videoSrcURL?: string | null;
  videoTitle?: string | null;
  layout?: string | null | undefined;
  autoPlay?: boolean;
  videoFile?: boolean;
  showControls?: boolean;
  muted?: boolean;
  loop?: boolean;
  width?: string;
}

const StyledVideo = styled.iframe<{ layout?: string | null }>`
  ${({ layout }) =>
    layout === "Grid/Gallery"
      ? `height: 250px;`
      : layout === "List"
      ? `height: 500px; max-width:800px;`
      : `height: 500px;`}
  width: 100%;
  /* height: 500px; */
`;

const Video = (props: Props) => {
  const { videoSrcURL, videoTitle, layout, autoPlay, videoFile, showControls, muted, loop, width } = props;
  if (videoSrcURL == null) {
    return null;
  }
  if (videoFile) {
    return (
      <video controls={showControls} src={videoSrcURL} autoPlay={autoPlay} muted={muted} loop={loop} width={width} />
    );
  }

  return (
    <div className="video">
      <StyledVideo
        src={`${videoSrcURL}${autoPlay ? "?autoplay=1" : ""}`}
        title={videoTitle ?? ""}
        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
        frameBorder="0"
        //@ts-ignore
        webkitallowfullscreen="true"
        mozallowfullscreen="true"
        allowFullScreen
        layout={layout}
      />
    </div>
  );
};

export default Video;
