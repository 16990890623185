import { useRef, useEffect, Dispatch, SetStateAction, useState, RefObject } from "react";

import { MOBILE_BREAKPOINT, TABLET_BREAKPOINT, LAPTOP_BREAKPOINT } from "./constants";
import { isBrowser } from "./helper";
import { ScreenWidth } from "./types";

const defaultScreenWidth = {
  isTabletWidth: false,
  isMobileWidth: false,
  isLaptopWidth: false,
};

// get previous const for comparsion
export function usePrevious<T>(value: T) {
  const ref = useRef<T>(value);

  useEffect(() => {
    ref.current = value;
  }, [value]);

  return ref.current;
}

// similar to use previous but will do an comparsion between prev & next prop
export function usePreviousCompare<T>(next: T, compare: (prev: T, next: T) => T) {
  const previousRef = useRef<T>(next);
  const previous = previousRef.current;

  const isEqual = compare(previous, next);

  useEffect(() => {
    if (previousRef.current == null) {
      return;
    }

    if (!isEqual && previousRef.current) {
      previousRef.current = next;
    }
  });

  return isEqual ? previous : next;
}

// the same as useState but will store the value in localStorage
export function useStickyState<S>(
  defaultValue: S | (() => S),
  key: string,
): [S, Dispatch<SetStateAction<S>>] {
  const [value, setValue] = useState(() => {
    const stickyValue = isBrowser() && window.localStorage.getItem(key);

    return stickyValue != null && stickyValue !== false ? JSON.parse(stickyValue) : defaultValue;
  });

  useEffect(() => {
    window.localStorage.setItem(key, JSON.stringify(value));
  }, [key, value]);
  return [value, setValue];
}

export function setStickyState(key: string, value: any) {
  return isBrowser() && window.localStorage.setItem(key, JSON.stringify(value));
}

export function getStickyState(key: string) {
  const stickyValue = isBrowser() && window.localStorage.getItem(key);
  return stickyValue != null && stickyValue !== false ? JSON.parse(stickyValue) : undefined;
}

// check if component has been mounted
export function useHasMounted() {
  const [mounted, setMounted] = useState(false);

  useEffect(() => {
    if (!mounted) {
      setMounted(true);
    }
  }, []);

  if (!mounted) {
    return false;
  }

  return mounted;
}

// event listener hook
export function useEventListener(eventName: string, handler: (args: any) => void) {
  if (!isBrowser()) {
    return;
  }
  const savedHandler = useRef<typeof handler>();

  useEffect(() => {
    savedHandler.current = handler;
  }, [handler]);

  useEffect(() => {
    const isSupported = window && window.addEventListener;
    if (!isSupported) return;

    //@ts-ignore
    const eventListener = (event: any) => savedHandler.current(event);
    window.addEventListener(eventName, eventListener);

    return () => {
      window.removeEventListener(eventName, eventListener);
    };
  }, [eventName, window]);
}

// checks screen width
export function useCheckScreenWidth(): ScreenWidth {
  if (!isBrowser()) {
    return defaultScreenWidth;
  }
  const [screenWidth, setScreenWidth] = useState(defaultScreenWidth);
  const hasMounted = useHasMounted();

  const checkScreenWidth = () => {
    if (window.innerWidth <= MOBILE_BREAKPOINT) {
      setScreenWidth({
        ...defaultScreenWidth,
        isMobileWidth: true,
      });
      return;
    }
    if (window.innerWidth <= TABLET_BREAKPOINT) {
      setScreenWidth({
        ...defaultScreenWidth,
        isTabletWidth: true,
      });
      return;
    }
    if (window.innerWidth <= LAPTOP_BREAKPOINT) {
      setScreenWidth({
        ...defaultScreenWidth,
        isLaptopWidth: true,
      });
      return;
    }
    if (window.innerWidth > LAPTOP_BREAKPOINT) {
      setScreenWidth(defaultScreenWidth);
      return;
    }
  };

  useEventListener("resize", checkScreenWidth);

  useEffect(() => {
    checkScreenWidth();
  }, []);

  useEffect(() => {
    if (hasMounted) {
      checkScreenWidth();
    }
  }, [hasMounted]);

  return screenWidth;
}

// set dark dark mode
export function useDarkMode(refObject?: RefObject<any>) {
  if (!isBrowser()) {
    return;
  }
  const [enabled, setEnabled] = useState(false);
  const element = refObject?.current ?? window.document.body;

  useEffect(() => {
    const className = "dark-mode";

    if (element) {
      if (enabled) {
        element.classList.add(className);
      } else {
        element.classList.remove(className);
      }
    }
  }, [enabled]);

  return [enabled, setEnabled];
}
