import React, { createElement } from "react";
import PortableText from "@sanity/block-content-to-react";

import { Maybe, SanityBlockContent } from "@graphql-types";
import { P } from "@util/standard";
import styled from "styled-components";
import { colors } from "@util/constants";
import Link from "./link";
interface Props {
  data: Maybe<SanityBlockContent> | undefined;
}

const StyledPortableText = styled(PortableText)`
  h1,
  h2,
  h3,
  h4,
  h5 {
    margin: revert;
  }
`;

const BlocksContent = ({ data }: Props) => {
  if (data == null) {
    return null;
  }

  const { _rawBlockContent } = data;

  if (_rawBlockContent == null) {
    return null;
  }

  const block = (props: any) => {
    const { style = "normal" } = props.node;
    const tag = style;
    if (/^h\d/.test(tag)) {
      return createElement(tag, { style: {} }, props.children);
    }
    return <P>{props.children}</P>;
  };

  return (
    <StyledPortableText
      className="portable-text"
      blocks={_rawBlockContent}
      serializers={{
        types: { block },
        marks: { blue, link },
      }}
      imageOptions={{ auto: "format", fit: "fill" }}
      projectId={process.env.SANITY_PROJECT_ID}
      dataset={process.env.GATSBY_NODE_ENV ?? "development"}
    />
  );
};

const blue = (props: any) => {
  return <span style={{ color: colors.blue }}>{props.children}</span>;
};

const link = (props: any) => {
  const { mark, children } = props;
  const { href } = mark;

  if (!href) return children;

  if (href.startsWith("#")) {
    const id = href.slice(1);
    const handleClick = (e) => {
      e.preventDefault();
      const target = document.getElementById(id);
      if (target) {
        const targetPosition = target.getBoundingClientRect().top + window.scrollY - 100;
        window.scrollTo({
          top: targetPosition,
          behavior: 'smooth',
        });
      }
    };
  
    return (
      <a href={`#${id}`} onClick={handleClick}>
        {children}
      </a>
    );
  }

  return <a href={href}>{children}</a>;
};

export default BlocksContent;
