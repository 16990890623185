import React from "react";
import styled from "styled-components";

import { SanityTestimonials } from "@graphql-types";
import { Container, Separator } from "@util/standard";
import { BlocksContent, Carousel } from "@global";
import { assets, TABLET_BREAKPOINT, colorsRGB, colors } from "@util/constants";

const Wrapper = styled(Container)`
  flex-direction: column;
  /* height: 90vh; */
  background-size: cover;
  background-image: url(${assets.background});
  background-position: right;
  background-color: ${colorsRGB.cascade()};
  border-left: 5px solid ${colors.marigold};
  /* padding-bottom: 35px; */
  @media only screen and (max-width: ${TABLET_BREAKPOINT}px) {
    border-top: 5px solid ${colors.marigold};
    border-left: unset;
  }
`;

const MainContainer = styled(Container)`
  width: 70%;
  margin: auto;
  flex-direction: column;
  padding: 80px 0;
  .slick-next {
    right: -120px;
  }
  h2 {
    margin-bottom: 0px;
    margin-top: 0px;
  }
  .slick-prev {
    left: -120px;
  }
  .slick-dots {
    position: initial;
    margin-top: 30px;
  }
  @media only screen and (max-width: ${TABLET_BREAKPOINT}px) {
    width: 90%;
    .slick-next {
      right: 0;
      bottom: -55px;
      top: unset;
    }
    .slick-prev {
      right: 50px;
      bottom: -55px;
      top: unset;
      left: unset;
    }
  }
`;

const BlockWrapper = styled.div`
  width: 100%;
`;

interface Props {
  data: SanityTestimonials;
}

const Testimonial = ({ data }: Props) => {
  const { headingContent, slides } = data;

  return (
    <Wrapper>
      <MainContainer>
        <Container width="100%" margin="auto" flexDirection="column">
          <BlocksContent data={headingContent} />

          <Separator width="100%" color="cascade" marginBottom={0} height="2px" marginTop={25} />
        </Container>
        {slides && (
          <Carousel infinite>
            {slides.map(slide => {
              if (slide == null) {
                return null;
              }

              return (
                <BlockWrapper key={slide._key}>
                  <Container margin="auto">
                    <BlocksContent data={slide} />
                  </Container>
                </BlockWrapper>
              );
            })}
          </Carousel>
        )}
      </MainContainer>
    </Wrapper>
  );
};

export default Testimonial;
